<template>
  <div>
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title">{{ $t("confirm.delete.employee") }}</h5>
      </template>
      <template slot="footer">
        <base-button type="secondary" @click="isModalShow = false">{{
          $t("cancel")
        }}</base-button>
        <base-button type="danger" @click="deleteItem">{{
          $t("delete")
        }}</base-button>
      </template>
    </modal>

    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="col d-block pb-2 d-sm-flex justify-content-sm-between">
              <div>
                <base-button
                  class="cancelBtn"
                  type="cancel"
                  @click="$router.push({ name: 'employeeList' })"
                >{{ $t("goBack") }}</base-button
                >
              </div>
              <div v-if="isManager" class="d-block pb-2 d-sm-flex justify-content-sm-between">
                <base-button
                  v-if="id != null && isManager && !isSpinnerShow"
                  type="danger"
                  class="mr-2 deleteBtn"
                  @click="isModalShow = true"
                >{{ $tc("delete") }}</base-button
                >
                <base-button
                  class="saveBtn"
                  :disabled="isBtnDisabled"
                  form="editEmployeeForm"
                  native-type="submit"
                  type="success"
                >{{ $t("save") }}</base-button
                >
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>
                <h1 v-if="id != null && isManager" class="mb-0">
                  {{ employee.name }}
                </h1>
              </div>
            </div>
          </template>
          <form id="editEmployeeForm" @change="disabledBtn(false)" @submit.prevent="manageItem()">
            <div class="col pb-4">
              <hr />
              <div v-if="!isSpinnerShow">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <base-input
                      v-if="!isSpinnerShow"
                      max="255"
                      v-model="employee.name"
                      :placeholder="$t('name')"
                      :label="`${$t('name')}*`"
                      required
                    ></base-input>
                    <Spinner v-else></Spinner>
                  </div>

                </div>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <base-input
                      inputType="email"
                      v-model="employee.email"
                      :placeholder="$t('email')"
                      :label="`${$t('email')}*`"
                      required
                    ></base-input>
                    <base-input
                      inputType="phone"
                      v-model="employee.phone"
                      :placeholder="$t('phone')"
                      :label="`${$t('phone')}*`"
                      :disabled="employee.isManager"                      
                    ></base-input>
                     <small v-if="employee.isManager" class="text-danger">(*){{ $t("phoneManagerWarning") }} </small>
                    <div>
                      <div>
                        <label class="control-label mt-4 mt-md-0">
                          {{ $t("gender") }}*
                        </label>
                      </div>
                      <base-radio
                        inline
                        name="female"
                        class="mt-0"
                        v-model="employee.gender"
                        >{{ $t("female") }}</base-radio
                      >
                      <base-radio
                        inline
                        name="male"
                        class="mt-0"
                        v-model="employee.gender"
                        >{{ $t("male") }}</base-radio
                      >
                      <base-radio
                        inline
                        name="diverse"
                        class="mt-0"
                        v-model="employee.gender"
                        >{{ $t("diverse") }}</base-radio
                      >
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="control-label mt-4 mt-md-0">{{
                      $t("picture")
                    }}</label>
                    <div>
                      <image-upload
                        ref="employee-picture"
                        @change="onImageChange"
                        :src="employee.picture"
                        :changeText="$t('change')"
                        :removeText="$t('delete')"
                        :select-text="$t('select.picture')"
                        altImage="employee"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Spinner v-else parentClasses="justify-content-center"></Spinner>
            </div>
          </form>
        </card>
      </div>
    </div>

    <div v-if="id != null" class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="col d-flex justify-content-between">
              <div>
                <h4 class="card-title mb-0">
                  {{ $t(`edit`) }} {{ $t(`password`).toLowerCase() }}
                </h4>
              </div>
            </div>
          </template>
          <form
            @change="disabledBtnPassword(false)"
            @submit.prevent="managePassword()"
          >
            <div class="col">
              <div class="d-block pb-2 d-sm-flex justify-content-sm-end">
                <div v-if="isManager">
                  <base-button
                    class="saveBtn"
                    :disabled="isBtnDisabledPassword"
                    native-type="submit"
                    type="success"
                    >{{ $t("save") }}</base-button
                  >
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="password"
                    @keydown.space.prevent
                    v-model="employee.password"
                    :placeholder="$t('password')"
                    :label="`${$t('password')}*`"
                  ></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="password"
                    @keydown.space.prevent
                    v-model="employee.passwordConfirm"
                    :placeholder="$t('passwordConfirm')"
                    :label="`${$t('passwordConfirm')}*`"
                  ></base-input>
                </div>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Modal,
  BaseButton,
  BaseInput,
  BaseRadio,
  Card,
  ImageUpload,
  Spinner,
} from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "EmployeeEdit",
  props: ["employee", "isSpinnerShow"],
  data: () => {
    return {
      id: null,
      route: "/employees",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      picture: null,
      isModalShow: false,
      inputFiles: ['employee-picture']
    };
  },
  computed: {
    ...mapState(["isManager"]),
  },
  methods: {
    createItem() {
      let employee = new FormData();

      employee.append("name", this.employee.name);
      employee.append("email", this.employee.email);
      employee.append("gender", this.employee.gender);
      employee.append("phone", this.employee.phone);
      if (this.picture) employee.append("media", this.picture);
      return employee;
    },
    createPassword(employee = null) {
      if (
        (this.employee.password === null ||
          this.employee.password === "" ||
          this.employee.password === undefined) &&
        (this.employee.passwordConfirm === null ||
          this.employee.passwordConfirm === "" ||
          this.employee.passwordConfirm === undefined)
      ) {
        if (!this.id)
          this.$toast.error(this.$t("notifications.error.mandatoryPassword"));
        return;
      }

      if (this.employee.password !== this.employee.passwordConfirm) {
        this.$toast.error(
          this.$t("notifications.error.passwordConfirmationNotMatch")
        );
        return;
      }
      if (!employee) employee = new FormData();
      employee.append("password", this.employee.password);
      employee.append("password_confirmation", this.employee.passwordConfirm);
      return employee;
    },
    manageItem() {
      const employee = this.createItem();
      if (this.id != null && this.isManager) {
        if (employee) this.updateItem(employee);
      }
    },
    managePassword() {
      const password = this.createPassword();
      if (this.id != null && this.isManager) {
        if (password) this.updateItem(password);
      }
    },
    onImageChange(file) {
      this.disabledBtn(false);
      this.picture = file;
    },
    async updateItem(employee) {
      this.disabledBtn(true);
      this.disabledBtnPassword(true);
      employee.append("_method", "PUT");
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.post(route, employee);
      if (response && response.data.status === "success") {
        this.employee.password = null;
        this.employee.passwordConfirm = null;
        this.clearInputFiles();
        this.$emit("editEmployee");
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    async deleteItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        this.$router.push({ name: "employeeList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    disabledBtnPassword(disabled) {
      this.isBtnDisabledPassword = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    clearInputFiles() {
      this.inputFiles.forEach( inputFile => {
        if(!this.$refs || !inputFile) return;

        this.$refs[inputFile]?.cleanInput()
      });
      this.disabledBtn(true);
    },
    ...mapActions(["setHasChangeForm"])
  },
  beforeMount() {
    this.id = this.$route.params.id;
  },
  components: {
    Modal,
    BaseButton,
    BaseInput,
    BaseRadio,
    Card,
    ImageUpload,
    Spinner,
  },
};
</script>

<style scoped>
</style>
