<template>
  <card>
    <template slot="header">
      <div class="col d-block pb-2 d-sm-flex justify-content-sm-between">
        <div>
          <base-button
              type="cancel"
              @click="$router.push({ name: 'employeeList' })"
          >{{ $t("goBack") }}</base-button
          >
        </div>
        <div>
          <base-button
              type="success"
              :disabled="isBtnDisabled"
              @click="manageItem"
          >{{ $t("save") }}</base-button
          >
        </div>
      </div>
      <div class="col d-flex flex-wrap mt-3">
        <h1 class="card-title mb-0 pl-0 col-12 col-md-auto">
          {{ $tc("services", 2) }}
        </h1>
        <span v-if="!services.some(x => x.checked)" class="employee-without-services col-12 col-md-10 my-auto p-0 pt-2 p-lg-0">
          <svg width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
            <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
          {{ $t('employeeWithoutServices') }}
        </span>
      </div>
    </template>
    <div class="col row table-full-width">
      <div class="ml-3 mb-3">
        <base-checkbox v-model="selectedAll" @change="selectedAllServices(selectedAll);">{{
          $t("selectAllServices").toUpperCase()
        }}</base-checkbox>
      </div>
      <div v-if="!isSpinnerShow" class="col-sm-12">
        <div v-for="(category, index) in categories" :key="index" class="mb-4">
          <h4>{{ category.name.toUpperCase() }}</h4>
          <hr />
          <ul
            v-for="(service, index) in services"
            :key="index"
            class="list-unstyled"
          >
            <li v-if="service.category.id === category.id">
              <base-checkbox
                v-model="service.checked"
                @change="changeServiceCheck(service.id, service.checked)"
                >{{ service.name.toUpperCase() }}</base-checkbox
              >
             
            </li>
          </ul>
        </div>
      </div>
      <Spinner v-else class="col-sm-12" parentClasses="justify-content-center"></Spinner>
    </div>
  </card>
</template>

<script>
import { Card, BaseCheckbox, Spinner } from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "EmployeeServices",
  props: ["servicesEmployee"],
  data() {
    return {
      id: null,
      routeUpdate: "/employees",
      route: "/service",
      routeCategories: "/service-categories",
      services: [],
      categories: [],
      isBtnDisabled: true,
      selectedAll: false,
      isSpinnerShow: true,
    };
  },
  watch: {
    servicesEmployee: function () {
      this.getServices();
    }
  },
  computed: {
    ...mapState(["isManager"]),
  },
  methods: {
    async getServices() {
      let route = this.route;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.services;
        this.services = data.data;
        if (data.last_page > 1) {
          for (let i = 2; i <= data.last_page; i++) {
            let routePagination = `${this.route}?page=${i}`;
            let responsePagination = await this.axios.get(routePagination);
            if (
              responsePagination &&
              responsePagination.data.status === "success"
            ) {
              const dataPagination = responsePagination.data.data.services;
              dataPagination.data.forEach((data) => {
                this.services.push(data);
              });
            }
          }
        }
      }
      this.controlCheckedServices();
    },
    async getServicesCategories() {
      let route = this.routeCategories;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.service_categories;
        this.categories = data;
        if (data.last_page > 1) {
          for (let i = 2; i <= data.last_page; i++) {
            let routePagination = `${this.route}?page=${i}`;
            let responsePagination = await this.axios.get(routePagination);
            if (
              responsePagination &&
              responsePagination.data.status === "success"
            ) {
              const dataPagination =
                responsePagination.data.data.service_categories;
              dataPagination.data.forEach((data) => {
                this.categories.push(data);
              });
            }
          }
        }
      }
    },
    controlCheckedServices() {
      if (this.servicesEmployee && !this.servicesEmployee.length > 0) {
        this.services.forEach((service) => {
          service.checked = false;
        });
      } else {
        this.services.forEach((service) => {
          this.servicesEmployee?.forEach((serviceEmployee) => {
            if (service.id === serviceEmployee.id) {
              service.checked = true;
            } else {
              if (!service.checked) service.checked = false;
            }
          });
        });
      }
      this.checkIsAllSelected();
      this.controlServicesCategories();
    },
    controlServicesCategories() {
      let servicesByCategory = {};
      this.categories.forEach((category) => {
        this.services.forEach((service) => {
          if (category.id === service.category.id) {
            servicesByCategory.categoryName = [];
            servicesByCategory.categoryName.push(category.name);
          }
        });
      });
      this.isSpinnerShow = false;
    },
    createItem() {
      let services = [];
      this.services.forEach((service) => {
        service.checked ? services.push(service.id) : services.push(0);
      });

      return services;
    },
    manageItem() {
      const services = this.createItem();
      if (this.id != null && this.isManager) {
        this.updateItem(services);
      }
    },
    async updateItem(services) {
      this.disabledBtn(true);
      const route = `${this.routeUpdate}/${this.id}`;
      let employee = {
        services: services,
      };
      let response = await this.axios.put(route, employee);
      if (response && response.data.status === "success") {
        this.$emit("editEmployeeServices");
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    selectedAllServices(value) {
      this.selectedAll = value;
      this.services = this.services.map((service) => {

        service.checked = value;
        return service
      });

      this.disabledBtn(false);
    },
    checkIsAllSelected(){
      this.selectedAll = this.services.every(service=> service.checked === true)
    },
    changeServiceCheck(serviceId, value){
      this.services = this.services.map((service) => {
        
        if(service.id === serviceId){
          service.checked = value;
        }
        return service
      })
      this.checkIsAllSelected();
      this.disabledBtn(false);
    },
    ...mapActions(["setHasChangeForm"])
  },
  mounted() {
    this.getServices();
    this.getServicesCategories();
  },
  beforeMount() {
    this.id = this.$route.params.id;
  },
  components: {
    Card,
    BaseCheckbox,
    Spinner,
  },
};
</script>

<style scoped>
.employee-without-services {
  font-size: 12px;
  color: red;
}
</style>
