<template>
  <div class="row">
    <div class="col-12">
      <card>
        <template slot="header">
          <div class="col d-block p-2 d-sm-flex justify-content-sm-between">
            <div>
              <base-button type="cancel" @click="$router.push({ name: 'employeeList' })">
                {{ $t("goBack") }}
              </base-button>
            </div>
          </div>
          <div class="col py-2 d-flex justify-content-between">
            <div>
              <h1 class="card-title mb-0">
                {{ $t(`schedule`) }}
              </h1>
            </div>
          </div>
        </template>
        <div class="col">
          <Timetable
            v-if="!isSpinnerShow"
            class="p-3"
            :schedules="timetables"
            @createSchedule="$emit('createSchedule', $event)"
            @editSchedule="$emit('editSchedule', $event)"
            @deleteSchedule="$emit('deleteSchedule', $event)"
          ></Timetable>
          <Spinner v-else></Spinner>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { BaseButton, Card, Timetable, Spinner } from "@/components/index";
import { mapActions } from 'vuex';

export default {
  name: "EmployeeSchedules",
  props: ["timetables", "isSpinnerShow"],
  data: () => {
    return {
      route: "/employees",
      isBtnDisabled: true,
      isModalShow: false,
      employeeSchedules: [],
    };
  },
  methods: {
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    ...mapActions(["setHasChangeForm"])
  },
  components: {
    BaseButton,
    Card,
    Spinner,
    Timetable,
  },
};
</script>