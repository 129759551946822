<template>
  <div class="content">
    <tabs :withBadge="anyEmployeeWithoutServices">
      <tab :name="$tc('edit', 2)" :selected="true">
        <EmployeeEdit
          :employee="employee"
          :is-spinner-show="isSpinnerShow"
          @editEmployee="getItem"
        ></EmployeeEdit>
      </tab>
      <tab :name="$tc('services', 2)">
        <EmployeeServices
          @editEmployeeServices="getItem"
          :servicesEmployee="employee.services"
        ></EmployeeServices>
      </tab>
      <tab :name="$tc('schedule', 2)">
        <EmployeeSchedules
          :timetables="timetables"
          :is-spinner-show="isSpinnerShow"
          @deleteSchedule="removeTimetable"
          @createSchedule="createTimetable"
          @editSchedule="editTimetable"
        ></EmployeeSchedules>
      </tab>
      <tab :name="$t('daysOff')">
        <EmployeesDaysOff></EmployeesDaysOff>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { Tabs, Tab } from "../../components";
import EmployeeEdit from "./EmployeeEdit";
import EmployeeSchedules from "./EmployeeSchedules";
import EmployeeServices from "./EmployeeServices";
import EmployeesDaysOff from "./EmployeesDaysOff";
import { mapState } from "vuex";

export default {
  name: "EmployeesManagement",
  data: () => {
    return {
      id: null,
      route: "/employees",
      employee: {
        name: null,
        email: null,
        gender: "diverse",
        picture: null,
        password: null,
        passwordConfirm: null,
      },
      timetables: [],
      services: [],
      isSpinnerShow: true,
      anyEmployeeWithoutServices: false
    };
  },
  computed: {
    ...mapState(["isManager"]),
  },
  methods: {
    async getItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        this.employee = response.data.data.employees;
        this.timetables = this.employee.timetables;
        this.services = this.employee.services;
        this.anyEmployeeWithoutServices = this.services.length === 0;
      }
      this.isSpinnerShow = false;
    },
    updateEmployee(employee) {
      this.employee = employee;
    },
    async removeTimetable(timetableId) {
      await this.axios.delete(`${this.route}/${this.employee.id}/timetables/${timetableId}`).then((result) => {
        if (result && result.data.status === 'success') {
          this.$toast.success(this.$t("notifications.success.general"));
          this.timetables = this.timetables.filter( timetable => timetable.id !== timetableId)
        } else {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      });
    },
    async createTimetable(timetable) {
      const route = `${this.route}/${this.employee.id}/timetables`;
      await this.axios.post(route, timetable).then((result) => {
        if (result && result.data.status === 'success') {
          if(!timetable.id) timetable.id = result.data.data.employees_timetables.id;
          this.$toast.success(this.$t("notifications.success.general"));
          this.timetables.push(timetable);
        } else {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      });
      
    },
    async editTimetable(timetable) {
      const route = `${this.route}/${this.employee.id}/timetables/${timetable.id}`;
      await this.axios.put(route, timetable).then( result => {
        if (result && result.data.status === 'success') {
          const timetableToEdit = this.timetables.find( schedule => schedule.id === timetable.id );
          if(timetableToEdit){
            timetableToEdit.start = timetable.start;
            timetableToEdit.end = timetable.end;
          }
          this.$toast.success(this.$t("notifications.success.general"));
        } else {
          this.$toast.error(this.$t("notifications.error.general"));
        }
      });
    }
  },
  beforeMount() {
    this.id = this.$route.params.id;
    if (this.id !== undefined) {
      this.getItem();
    }
  },
  components: {
    Tabs,
    Tab,
    EmployeeEdit,
    EmployeeSchedules,
    EmployeeServices,
    EmployeesDaysOff,
  },
};




/* 
    async updateSchedules() {
      const route = `${this.route}/${this.id}/timetables`;
      const editedSchedules =  this.employeeSchedules.filter( schedule => schedule.isEdit );
      const newsSchedules = this.employeeSchedules.filter( schedule => schedule.temp_id  );
      
      if (newsSchedules.length === 0 && editedSchedules.length === 0 && this.deletedSchedules.length === 0) return;

      if(this.deletedSchedules && this.deletedSchedules.length > 0) await this.deleteSchedules(route);
      if(editedSchedules && editedSchedules.length > 0) await this.editSchedules(editedSchedules, route);
      if(newsSchedules && newsSchedules.length > 0) await this.createSchedules(newsSchedules, route);

      this.createCloneSchedules();
      this.$emit("update-timetables", this.employeeSchedules);
      this.disabledBtn(true);
    },
    async editSchedules(editedSchedules, route) {
      await Promise.all(
        editedSchedules.map( async schedule => {
          if(schedule && schedule.isEdit) delete schedule.isEdit;

          await this.axios.put(`${route}/${schedule.id}`, schedule).then( result => {
            if (result) this.$toast.success(this.$t("notifications.success.general"));
          });
        })
      );
    },
    async createSchedules(newsSchedules, route) {
      await Promise.all(
        newsSchedules.map( async schedule => {
          await this.axios.post(route, schedule).then( result => {
            if (result) {
              if(!schedule.id) schedule.id = result.data.data.employees_timetables.id;
              
              delete schedule.temp_id;

              this.$toast.success(this.$t("notifications.success.general"));              
            } else {
              const indexItemError = this.employeeSchedules.findIndex(sch => schedule.temp_id === sch.temp_id);
              this.employeeSchedules.splice(indexItemError, 1)
            }
          })
        })
      );
    }, */

</script>